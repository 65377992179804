


/* Navbar */

*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Alegreya', serif;
  scroll-behavior: smooth;
}
nav{
  position: fixed;
  top: 0;
  width: 100vw;
  background:#fff;
  padding: 5px 20px;
  display: flex;
  align-items: center;
  scroll-behavior: smooth;
  z-index: 11;
}
nav a {
  color: #000000;
}
nav a:hover{
  color: #000000;
}
.logo{
  flex: 1;
}
.logo a{
  display: flex;
  align-items: center;
  font-size:20px;
}
.logo img{
  width:250px;
  height: 150px;
  margin-right: 10px;
  margin-top: -30px;
}
.menu{
  display: flex;
  list-style: none;
  
  align-items: center;
}
.menu li{
  padding: 4px 15px;
  font-size: 18px;
}
.menu li a{
  text-decoration: none;
  font-weight: bold;
padding-right: 13px;
  padding-left: 13px;
  padding-top: 7px;
  padding-bottom: 7px;
  transition: all 0.1.6s;
}

.menu li a:hover{
  background-color: #f4f029;
  border-radius: 23px;
  
}
.toggle {
  font-size:30px;
  display: none;
  
}
.nav-btn{
  padding: 5px;
  cursor: pointer;
  background-color: transparent;
  visibility: hidden;
  opacity: 0;
}

#contact{
  background-color:#f4f029;
  border-radius: 15px;
  padding: 5px 20px;
  margin-top: 3px;
}
#contact:hover{
  color: black;
}

.menu ion-icon{
  margin: -3px;
}
/* Media Queries */


@media (max-width:850px) {
  nav{
    display: flex;
    flex-direction: column;
    /* padding: 15px 20px; */
    justify-content: space-between;
    align-items: center;
  }
  .menu{
    margin-top: 15px;
      display: block;
      position: relative;
      left: 20px;
      padding: 20px -15px 20px -15px ;

  }
  .menu li NavLink{
    margin: 10px;
  }
  .logo{
    width: 7%;
    display: block;
    margin-left: -30px;
  }
  .nav-btn{
    visibility: visible;
    opacity: 1;
  }
  nav{
    position: fixed;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      z-index: 11;
      width: 100vw;
  }
  nav li a{
    position: relative;
    top: 10px;
  }
    menu li {
      padding: 4px 15px;
      font-size: 18px;
    }

}





/* Home page */
#header{
  width: 100%;
  height: 65vh;
  margin-top: 130px;
}
#header h1{
  margin: 0 0 10px 0;
  font-size: 48px;
  font-weight: 700;
  color: black; 
}
#header .btn-get-started{
  font-weight: 500;
  font-size: 16px;
  letter-spacing:1px;
  display: inline-block;
  padding: 17px 30px;
  border-radius:50px;
  transition: 0.5s;
  color: #fff;
  text-decoration: none;
  background-color: black;

}
#header .btn-get-started:hover{
  background:#f4f029;
  color: black;
}
.header-img{
  text-align: right;
  width: 100vw; 
}
.image-next{
  width: 100vw;
}
#header .animated{
  animation: up-down 2s ease-in-out infinite alternate-reverse both;
}
@-webkit-keyframes up-down{
  0%{
    transform: translateY(10px);
  }
  100%{
    transform: translateY(-10px);
  }
}
@keyframes up-down{
  0%{
    transform: translateY(10px);
  }
  100%{
    transform: translateY(-10px);
  }
}

@media (max-width: 768px){
  #header h1{
    width: 100%;
    font-size: 40px;
    margin-top: 123px;
  }
    .header-img{
      display: none;
    }
}
@media (min-width: 769px) and (max-width:991px){
#header h1{
  width: 80vw;
  font-size:40px;
}
.header-img{
  display: none;
}

}


/* Cards */


.title{
  text-align: center;
}

.max-width{
  display: inline-block;
}


#services .serv-content .card {
  width: calc(30% - 30px);
 margin: 10px 10px;
  display: block;
  margin-left: 50px;
  float: left;
  text-align: center;
  border-radius: 10px;
  padding: 50px 25px;
  cursor: pointer;
  transition: all 0.3s ease;
  background: linear-gradient(137.19deg, #FFFFFF 66.28%, rgba(251, 255, 49, 0) 66.28%, #E6EB00 107.62%);
}
#services .serv-content .card:hover {
  background:#f4f029
}
#services .serv-content .card .box {
  transition: all 0.3s ease;
}
#services .serv-content .card .box:nth-child(3){
  padding: 10px 20px;

}
#services .serv-content .card:hover .box {
  transform: scale(1.05);
}


#services .serv-content .card .text {
  font-size: 25px;
  font-weight: 500;
  margin: 7px 0 7px 0;
}
.learnmore{
  font-weight: 500;
  font-size: 16px;
  letter-spacing:1px;
  display: inline-block;
  margin: 20px auto;
  position: relative;
  left: 43vw;
  padding: 10px 30px;
  border-radius:50px;
  transition: 0.5s;   
  color: #fff;
  text-decoration: none;
  background-color:black;
}
.learnmore:hover{
  background:#f4f029;
  color: black;
}

/* Media Query */
/* For Mobile */
@media (max-width:468px) {
  #services .serv-content .card{
    width: calc(90% - 50px);

  }
  .max-width{
    
    margin-top: 200px;
  }
  .learnmore{
    left: 28vw;
  }
}
@media (max-width: 768px){
  .max-width{
    margin-top:180px;
  }
  #services .serv-content .card{
    width: calc(90% - 30px);

  }
  .learnmore{
    left: 30vw;
  }

}
/* For tablet */
@media (min-width: 769px) and (max-width:991px){
  #services .serv-content .card{
    width: calc(30% - 40px);

  }
  .learnmore{
    left: 38vw;
  }

}






/* About */
.heading h1{
  text-align: center;
  margin-top:30px;
}
.para{
  width:100%;
  text-align: center;
  float:center;
  font-weight: bold;
  margin-top:20px;
  margin-bottom: 80px;
  padding-left: 60px;padding-right: 60px;
}
.abt-content .aboutcard {
  width: calc(30% - 30px);
 margin: 10px 10px;
  display: block;
  margin-left: 50px;
  float: left;
  text-align: center;
  border-radius: 10px;
  padding: 50px 25px;
  cursor: pointer;
  transition: all 0.3s ease;
}
.abt-content .aboutcard:hover {
  background: #fff;
}
.abt-content .aboutcard .box {
  transition: all 0.3s ease;
}
.abt-content .aboutcard:hover .aboutbox {
  transform: scale(1.05);
}


.abt-content .aboutcard .imagee {
  font-size: 25px;
  font-weight: 500;
  margin: 7px 0 7px 0;
}

.lastimg{
  margin-top: 450px;
  margin-bottom: 100px;
  text-align: center;
}


/* Media Query */
/* For Mobile */
@media (max-width:468px){
  .abt-content .aboutcard{
    width: calc(90% - 50px);

  }
  .left{
    height:350px;
   padding:10%;
   box-sizing: border-box;
    width: 100%;
  }
  .right{
    height:350px;
    box-sizing: border-box;
    padding:10%;
     width: 100%; 
  }
}

@media (max-width: 768px){
  .abt-content .aboutcard{
    width: calc(90% - 30px);

  }
  .right{
    margin-top:40px;
  }
}
/* For tablet */
@media (min-width: 769px) and (max-width:991px){
  .right{
    margin-top:40px;
  }
  .abt-content .aboutcard{
    width: calc(90% - 50px);

  }
  
}


/* Footer */
.call{
  margin-top: 150px;
  margin-right: 40px;
}
.contactus-section{
  background-color:#f4f029;
}

.contact-leftside h1{
 font-size:  60px;
 margin-left: -80px;
}

.contact-input-field{
  margin-bottom:2.5rem;
  
}

.contact-rightside form .form-control{
  border: 1px solid black;
  height: 3rem;
  border-radius:0.5rem;
  font-size: 1rem;
  padding-left: 1rem;
  font-weight: lighter;
}
.contact-rightside form .form-check-input{
  width: 1rem;
  height: 1rem;
}

.form-checkbox-style{
  margin: 2rem 0;
}

#contact-submit{
  background-color: black;
  color: white;
  margin: 20px 0;
}

#spaceabovefirst{
  margin-top: 20px;
}

/* Footer2 */

.footer-left{
  background-color: #000000;
  color: #fff;
  padding: 7px;
  height: 40px;
}
.footer-left h5{
  float: right;
  margin-right: 30px;
  font-size: 16px;

}

/* Chat Support */


.ce-my-message-bubble {
  background-color: #000000 !important;
}

.ce-chat-title-text {
  color: #000000 !important;
}

.ce-chat-subtitle-text {
  color: #000000 !important;
  font-weight: 600;
}

#ce-send-message-button {
  position: relative !important;
  bottom: 2px !important;
  left: 1px !important;
  background-color: #000000 !important;
}

.ce-my-message-timestamp {
  color: #000000 !important;
}

.transition-3 {
  transition: all 0.33s ease;
  -webkit-transition: all 0.33s ease;
  -moz-transition: all 0.33s ease;
}

.transition-5 {
  transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
}




/* Media Query */
/* For Mobile */
@media (max-width:468px){
  .contact-leftside h1{
    margin-left: 20px;
    text-align: center;
  }
}

@media (max-width: 768px){
  .contact-leftside h1{
    margin-left: 20px;
    text-align: center;
  }
}
@media (min-width: 769px) and (max-width:991px){
  .contact-leftside h1{
    text-align: center;
    margin-left: 20px;
  }

}


#rotate {
  vertical-align: top;
  transform: rotate(7deg);
  -ms-transform: rotate(120deg);
  /* IE 9 */
  -moz-transform: rotate(90deg);
  /* Firefox */
  -webkit-transform: rotate(90deg);
  /* Safari and Chrome */
  -o-transform: rotate(90deg);
  /* Opera */
  position: fixed;
  right: -66px;
  top: 60vh;
  padding: 7px 20px 7px 20px;
  border: none;
  background-color:#000;
  color: #fff;
  transition: all 0.19s;
}
#rotate a{
  text-decoration: none;
  color: #fff;
  transition: all 0.19s;
}
.upsideFlex{
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;  
}
.upsideScroll{
  align-self: flex-start;
  position: fixed;
  bottom: 50px;
  right: 10px;
  z-index: 10;
  transform: scale(2);
  color: #000;
}



